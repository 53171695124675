/* 全体のレイアウト */
.main-menu, .quiz-container { 
  min-height: 100vh;
  background-color: #f8f9fa;
  font-family: 'Roboto', sans-serif;
}

/* ヘッダー */
.header {
  background-color: #2c3e50;
  color: #ecf0f1;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 3px solid #34495e;
}

.app-title {
  font-size: 2rem;
  font-weight: bold;
  color: #ecf0f1;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
}

.sign-out-btn {
  background-color: rgba(236, 240, 241, 0.15);
  border: none;
  color: #ecf0f1;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.sign-out-btn:hover {
  background-color: rgba(236, 240, 241, 0.3);
  color: #ffffff;
}

/* メインコンテンツ */
.content, .quiz-container {
  max-width: 800px;
  margin: 2rem auto;
  padding: 0 1rem;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* クイズボタン */
.quiz-button {
  display: block;
  margin-bottom: 2rem;
  text-decoration: none;
}

.quiz-button-inner {
  background: linear-gradient(135deg, #38b2ac, #319795);
  color: #f7fafc;
  padding: 1.5rem;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s, box-shadow 0.3s;
}

.quiz-button-inner:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 20px rgba(56, 178, 172, 0.4);
}

.quiz-button .icon {
  font-size: 2rem;
  margin-right: 1rem;
}

.quiz-button .text {
  font-size: 1.5rem;
  font-weight: bold;
}

/* 機能カード */
.features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.feature-card {
  background-color: #ffffff;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #2d3748;
  transition: transform 0.3s, box-shadow 0.3s;
}

.feature-card:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.feature-card .icon {
  font-size: 1.8rem;
  margin-right: 0.5rem;
  color: #4a5568;
}

.feature-card.wide {
  grid-column: 1 / -1;
}

/* 管理者セクション */
.admin-section {
  border-top: 1px solid #e2e8f0;
  padding-top: 1.5rem;
  margin-top: 2rem;
}

.admin-title {
  display: flex;
  align-items: center;
  font-size: 1rem;
  color: #718096;
  margin-bottom: 1rem;
}

.admin-title .icon {
  margin-right: 0.5rem;
}

.admin-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 1rem;
}

.admin-card {
  background-color: #f7fafc;
  padding: 0.75rem;
  border-radius: 6px;
  text-align: center;
  text-decoration: none;
  color: #4a5568;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.admin-card:hover {
  background-color: #edf2f7;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* クイズコンポーネント用スタイル */
.options-container {
  width: 100%;
}

.option-button {
  margin-bottom: 10px !important;
  transition: all 0.3s ease !important;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;
}

.option-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15) !important;
}

.option-button.selected {
  transform: translateY(2px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) inset !important;
}

.feedback {
  margin-top: 20px;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
}

.feedback.correct {
  background-color: rgba(0, 255, 0, 0.1);
  color: green;
}

.feedback.incorrect {
  background-color: rgba(255, 0, 0, 0.1);
  color: red;
}

.next-button {
  margin-top: 30px !important;
  padding: 10px 20px !important;
  font-size: 1.2em !important;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;
  transition: all 0.3s ease !important;
}

.next-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15) !important;
}

.footer {
  background-color: #2c3e50;
  color: #ecf0f1;
  padding: 1rem;
  text-align: center;
  position: fixed;
  bottom: 0;
  width: 100%;
}

.footer-link {
  color: #ecf0f1;
  text-decoration: none;
  margin: 0 1rem;
}

.footer-link:hover {
  text-decoration: underline;
}

.policy-page {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
}

.policy-content {
  margin-bottom: 2rem;
}

.back-link {
  display: inline-block;
  padding: 0.5rem 1rem;
  background-color: #2c3e50;
  color: #ecf0f1;
  text-decoration: none;
  border-radius: 4px;
}

.back-link:hover {
  background-color: #34495e;
}

/* アクセシビリティのための追加スタイル */
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.issued-date {
  text-align: right;
  margin-top: 2rem;
  font-size: 0.9rem;
  color: #7f8c8d;
}

.spacer {
  margin-bottom: 2rem;
}

/* 指示文のスタイル */
.instruction-text {
  color: rgba(0, 0, 0, 0.6); /* テキストの色を薄く */
  font-size: 0.6rem; /* フォントサイズを小さく */
}

/* 問題の単語のスタイル */
.question-word {
  font-size: 120%; /* フォントサイズを5%増加 */
}

.options-wrapper {
  position: relative;
  width: 100%; /* 必要に応じて調整 */
  display: flex;
  justify-content: center; /* 横方向を中央揃え */
  align-items: center; /* 縦方向を中央揃え */
}

.feedback-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  animation: fadeInHoldOut 2.5s ease-out; /* アニメーションの時間を2秒 */
  pointer-events: none;
}

.feedback-image {
  width: 120px;
  height: 120px;
  opacity: 0.7;
}

@keyframes fadeInHoldOut {
  0% { opacity: 1; }
  5% { opacity: 1; }
  95% { opacity: 1; }
  100% { opacity: 0; }
}

/* モバイル対応 */
@media (max-width: 600px) {
  .header {
    flex-direction: column;
    align-items: flex-start;
  }

  .sign-out-btn {
    margin-top: 1rem;
  }

  .quiz-button-inner {
    flex-direction: column;
    text-align: center;
  }

  .quiz-button .icon {
    margin-right: 0;
    margin-bottom: 0.5rem;
  }
}

/* 追加するCSSクラスはここから */
.results-container {
  max-width: 800px; /* コンテナの最大幅 */
  width: 90%;        /* コンテナの幅を画面の90%に設定 */
  margin: 2rem auto; /* 上下のマージンを追加し、中央揃え */
  padding: 20px;     /* 内側の余白を追加 */
  box-sizing: border-box; /* パディングとボーダーを含めたサイズ計算 */
  background-color: #ffffff; /* 背景色を白に設定（必要に応じて調整） */
  border-radius: 8px; /* 角を丸くする */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* 軽いシャドウを追加 */
}

.chart-wrapper {
  position: relative;
  height: 400px; /* チャートの高さを固定 */
}

/* レスポンシブデザインの強化 */
@media (max-width: 800px) {
  .results-container {
    max-width: 600px;
    padding: 15px;
  }

  .chart-wrapper {
    height: 350px;
  }
}

@media (max-width: 600px) {
  .results-container {
    max-width: 100%;
    padding: 10px;
    margin: 1rem auto;
  }

  .chart-wrapper {
    height: 300px;
  }

  .results-container h2 {
    font-size: 1.5rem; /* 見出しのフォントサイズを調整 */
  }
}

@media (max-width: 400px) {
  .chart-wrapper {
    height: 250px;
  }

  .results-container h2 {
    font-size: 1.2rem; /* 見出しのフォントサイズをさらに調整 */
  }
}

:root {
  --footer-height: 15px; /* フッターの高さを固定 */
}
.results-container {
  padding: 20px;
  max-width: 1000px;
  margin: 0 auto;
}

.results-container h2 {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
  color: #333;
}

.results-container .icon {
  font-size: 1.5em;
}

.results-container .text {
  font-size: 1.2em;
  font-weight: bold;
}

.chart-wrapper {
  background: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  margin-bottom: 30px;
  min-height: 400px;
  position: relative;
}



/* メインコンテンツコンテナにボトムパディングを追加 */
.main-menu,
.quiz-container,
.content,
.results-container,
.search-container { /* 検索画面のコンテナが .search-container と仮定 */
  padding-bottom: var(--footer-height); /* フッターの高さ分のパディングを追加 */
}

/* レスポンシブデザインの強化 */
@media (max-width: 800px) {
  .results-container,
  .search-container {
    max-width: 600px;
    padding: 15px;
  }

  .chart-wrapper {
    height: 350px;
  }
}

@media (max-width: 600px) {
  .results-container,
  .search-container {
    max-width: 100%;
    padding: 10px;
    margin: 1rem auto;
  }

  .chart-wrapper {
    height: 300px;
  }

  .results-container h2,
  .search-container h2 { /* 検索画面の見出しが h2 の場合 */
    font-size: 1.5rem; /* 見出しのフォントサイズを調整 */
  }
}

@media (max-width: 400px) {
  .chart-wrapper {
    height: 250px;
  }

  .results-container h2,
  .search-container h2 {
    font-size: 1.2rem; /* 見出しのフォントサイズをさらに調整 */
  }
}
/* 追加するCSSクラスはここまで */

